.Quiz {
  .QuestionPage {
    &__header {
      @extend .flex;

      flex: 0 0 150px;

      &-index {
        flex: 1 0 auto;
      }

      &-progress {
        flex: 0 0 100px;
        margin: 2em 2em auto auto;
        .Timer {
          width: 150px;
          height: 150px;
        }
      }
    }

    &__question {
      @extend .flex, .flex--center;
      flex: 0 0 480px;

      gap: 40px;
      justify-content: center;

      &-banner {
        flex: 0 0 100px;

        img {
          max-width: 250px;
        }
      }

      &-label {
        flex: 0 0 1020px;
        font-size: 56px;
        line-height: 1.2;
        letter-spacing: 8px;
      }
    }

    &__playerlist {
      // top: 650px;
      // height: 413px;
      // margin-top: 612px;
      flex: 0 0 440px;

      &.announcement {
        &.firstAnnouncement {
          animation: announce 1s forwards;
        }

        @keyframes announce {
          from {
            transform: translate3d(0, -364px, 0);
          }
          to {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &__content {
      @extend .full-window, .flex, .flex--middle, .flex--column, .bg--blue;

      @extend .font-bell-heavy;

      // justify-content: space-evenly;
      width: 100%;
      flex: 0 1;
      font-size: 20px;
      color: #fff;
      // DEFAULT
      .question {
        transition: all 1s ease;
        text-transform: uppercase;

        .banner {
          opacity: 0;
        }
        .label {
          opacity: 0;
          transition: all 1s ease;
        }

        .answer {
          opacity: 0;
          transition: all 1s ease;
        }
      }

      .header {
        transition: all 1s ease;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        width: 0;
        white-space: nowrap;
        padding: 2em;
        text-transform: uppercase;

        .index {
          font-size: 40px;
          transition: all 1.2s ease;
        }

        .instruction {
          opacity: 0;
          transition: all 1s ease;
          color: #00c1f3;
        }
      }

      .progress {
        transition: all 1s ease;
      }

      // END DEFAULT
      //* Get ready *//
      &.announcement {
        .question {
          opacity: 0;

          .label {
            display: none;
          }

          .answer {
            display: none;
          }
        }

        .header {
          // font-size: 60px;
          width: 100%;
          top: calc(359px - 2em);
          transition: none;

          .index {
            font-size: 100px;
            letter-spacing: 15px;
            animation: fadeDown 1.5s ease forwards;

            @keyframes fadeDown {
              from {
                opacity: 0;
                transform: translate3d(0, -100vh, 0);
              }
              to {
                opacity: 1;
                transform: translate3d(0, 0vh, 0);
              }
            }
          }

          .instruction {
            font-size: 50px;
            padding-top: 10px;
            letter-spacing: 8px;
            animation: fadeIn 2s forwards 1s;
            animation-delay: 0.5s;
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }

              50% {
                opacity: 0.4;
              }

              100% {
                opacity: 1;
              }
            }
          }
        }

        .progress {
          opacity: 0;
        }
      }

      // * question *//
      &.answering {
        .question {
          .banner {
            animation: fadeInLeft 1s ease-in;
            animation-fill-mode: forwards;

            @keyframes fadeInLeft {
              0% {
                opacity: 0;
                transform: translateX(-10px);
              }
              100% {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
          .label {
            //animation-delay: 0.5s;
            animation: fadeInRight 1s ease;
            animation-fill-mode: forwards;
            // animation-duration: 3s;
            @keyframes fadeInRight {
              0% {
                opacity: 0;
                transform: translateX(10px);
              }
              100% {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
          .answer {
            display: none;
          }
        }

        .header {
          transition-delay: 0.15s;
          .index {
            opacity: 1;
            letter-spacing: 8px;
          }

          .instruction {
            display: none;
            opacity: 0;
          }
        }

        .progress {
          opacity: 1;
        }
      }

      // * answer *//
      &.ending {
        .question {
          .banner {
            opacity: 1;
          }
          .label {
            display: none;
          }

          .answer {
            text-align: center;
            animation: fadeIn 1s ease;
            animation-fill-mode: forwards;
          }
        }

        .header {
          .index {
            opacity: 1;
            letter-spacing: 8px;
          }

          .instruction {
            display: none;
          }
        }

        .progress {
          opacity: 1;
        }
      }

      //       ._title {
      //         font-size: 95px;
      //         line-height: 115px;
      //         text-align: center;
      //         padding: 30px 0;
      //       }
      //
      //       ._head {
      //         font-size: 60px;
      //         line-height: 75px;
      //         text-align: center;
      //         padding: 30px 0;
      //       }
      //
      //       // states
      //       &.announcement {
      //         gap: 219px;
      //         padding-top: 80px;
      //
      //         .question {
      //           display: none;
      //         }
      //
      //         .answer {
      //           display: none;
      //         }
      //
      //         .progress {
      //           display: none;
      //         }
      //
      //         .header {
      //           margin-top: 113px;
      //           margin-bottom: 406px;
      //           opacity: 0;
      //           @extend .flex, .flex--middle, .flex--column;
      //
      //           @extend ._title;
      //           // flex: 1 1 20vh;
      //           animation: fadeDown 1s forwards 1s;
      //
      //           @keyframes fadeDown {
      //             from {
      //               opacity: 0;
      //               transform: translate3d(0, -100vh, 0);
      //             }
      //
      //             to {
      //               opacity: 1;
      //               transform: translate3d(0, 0vh, 0);
      //             }
      //           }
      //   }
      // }

      //       &.answering {
      //         justify-content: center;
      //         align-items: center;
      //         padding-bottom: 400px;
      //         gap: 120px;
      //
      //         .question {
      //           @extend ._title;
      //           width: 75vw;
      //           font-size: 60px;
      //           line-height: 60px;
      //           margin-top: 50px;
      //           z-index: 2;
      //           animation: show 1s ease;
      //
      //           @keyframes show {
      //             0% {
      //               opacity: 0;
      //             }
      //
      //             50% {
      //               opacity: 0.6;
      //             }
      //
      //             100% {
      //               opacity: 1;
      //             }
      //           }
      //         }
      //
      //         .answer {
      //           display: none;
      //         }
      //
      //         .progress {
      //           margin-top: -40px;
      //           height: 10%;
      //         }
      //
      //         .header {
      //           @extend ._head;
      //           height: 10vh;
      //           font-size: 50px;
      //           animation: showScale 0.75s forwards;
      //
      //           @keyframes showScale {
      //             from {
      //               transform: translateY(25vh) scale(1);
      //             }
      //
      //             to {
      //               transform: translateY(0vh) scale(0.4);
      //             }
      //           }
      //         }
      //       }
      //
      //       &.ending {
      //         justify-content: center;
      //         align-items: center;
      //         padding-bottom: 400px;
      //         gap: 120px;
      //
      //         .question {
      //           display: none;
      //         }
      //
      //         .answer {
      //           @extend ._title;
      //           width: 75vw;
      //           font-size: 60px;
      //           line-height: 60px;
      //           margin-top: -20px;
      //           z-index: 2;
      //           animation: show 1s ease;
      //
      //           @keyframes show {
      //             0% {
      //               opacity: 0;
      //             }
      //
      //             50% {
      //               opacity: 0.6;
      //             }
      //
      //             100% {
      //               opacity: 1;
      //             }
      //           }
      //         }
      //
      //         .progress {
      //           margin-top: -40px;
      //           height: 10%;
      //         }
      //
      //         .header {
      //           @extend ._head;
      //           height: 10vh;
      //           font-size: 50px;
      //           animation: showScale 0.75s forwards;
      //           padding-top: 125px;
      //
      //           @keyframes showScale {
      //             from {
      //               transform: translateY(25vh) scale(1);
      //             }
      //
      //             to {
      //               transform: translateY(0vh) scale(0.4);
      //             }
      //           }
      //         }
      //       }
      //
      //       &.score {
      //         .question {
      //           @extend ._title;
      //         }
      //
      //         .progress {
      //           display: none;
      //         }
      //
      //         .header {
      //           @extend ._head;
      //           font-size: 50px;
      //           // animation: showScale 0.75s forwards;
      //           transform: scale(0.4);
      //           // @keyframes showScale {
      //           //   from {
      //           //     opacity: 0;
      //           //   }
      //           //   to {
      //           //     opacity: 1;
      //           //   }
      //           // }
      //         }
      //       }

      .progress {
        @extend .flex, .flex--center, .flex--column;
      }
    }
  }
}
