.Quiz {
  .HomePage {
    &__content {
      @extend .flex, .flex--middle, .flex--row;
      background-color: #ffffff;
      width: 100%;
      height: 100vh;
      flex: 0 1;
      overflow: hidden;

      img {
        width: 30vw;
      }
    }

    &__column {
      @extend .flex, .flex--center, .flex--middle, .flex--column;

      flex: 0 1 50%;
      text-align: center;
    }

    &__video {
      background-color: #afafaf;
      position: fixed;
      width: 800px;
      top: 340px;
      left: 1040px;
      transition: all 2s ease;
      object-fit: cover;

      &--fullscreen {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__right-img {
      min-height: 1050px;
      min-width: 1050px;
      height: 100vh;
    }
  }
}
