@import "./fonts.scss";
@import "./buttons.scss";
@import "./helpers.scss";
@import "./page.scss";
@import "./modal.scss";
@import "./timer.scss";
@import "./toolbar.scss";
@import "./quiz/quiz.scss";
@import "./texting/texting.scss";
@import "./leaderboard/leaderboard.scss";
@import "./animations.scss";
@import "./waves.scss";

:root {
  color-scheme: only light;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: Helvetica;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #070942;
  color: #fff;

  @media (prefers-color-scheme: dark) {
    background-color: #070942;
    color: #fff;
  }
}

// reset styles
* {
  box-sizing: border-box;
}

// loading icon
.loading {
  max-height: 100%;
}
