.Leaderboard {
  .PodiumPage {
    &__content {
      @extend .full-window, .flex, .flex--column, .flex--center, .bg--blue;

      padding: 136px 0px 0px 0px;
      //height: 100vh;
      .waveContainer {
        z-index: 0;
      }

      * {
        @extend .font-bell-heavy;
        color: #ffffff;
      }

      canvas {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      font-size: 54px;
      line-height: 58px;
      text-transform: uppercase;
      letter-spacing: 5px;
      color: #00c1f3;
      text-align: center;
      padding-bottom: 10px;
      z-index: 1;
    }

    &__sub-title {
      font-size: 120px;
      line-height: 1.2;
      text-transform: uppercase;
      letter-spacing: 12px;
      text-align: center;
      z-index: 1;
      padding-bottom: 91px;
    }

    &-winner {
      position: relative;

      &__item {
        @extend .flex;

        padding: 40px 0;
        width: 700px;
        margin: 0 auto;
        position: relative;
        justify-content: center;

        animation-duration: 2.5s;
        animation-name: fadeIn;
        animation-fill-mode: both;
        --animation-delay: 0s;
        animation-delay: var(--animation-delay);

        &[hide="true"] {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -350px;
          animation-name: fadeOut;
          animation-duration: 0.5s;
        }

        &--delay {
          --animation-delay: 0.6s;
        }

        &-image {
          position: relative;
          width: 284px;
          height: 284px;

          &::before {
            content: url("../../assets/decorations/crown.svg");
            position: absolute;
            top: -90px;
            left: -41px;
          }

          img {
            width: 284px;
            outline: 7px solid #ffffff;
            border-radius: 50%;
          }
        }

        &-player {
          @extend .flex, .flex--column;

          position: relative;
          margin-left: 20px;

          align-items: flex-start;
          width: initial;
          height: initial;
          opacity: 1;
          background: none;
          align-self: center;

          &-initials {
            color: #ffffff;
            font-size: 60px;
            line-height: 64px;
          }

          &-score {
            color: #ffffff;
            font-size: 60px;
            line-height: 64px;
          }
        }
      }
    }

    &-list {
      position: relative;
      flex: 1 1;
      > div {
        position: relative;
      }
      &__item {
        @extend .flex, .flex--row, .flex--center;

        width: 780px;
        padding: 20px 0;

        // Default Animations
        transition: all 1s ease;

        --transition-delay: 1s;
        transition-delay: var(--transition-delay);
        opacity: 0;
        transform: translateX(300px);

        &-image {
          z-index: 1;

          img {
            width: 214px;
            outline: 7px solid #ffffff;
            border-radius: 50%;
          }
        }

        &-player {
          @extend .flex, .flex--center;

          flex: 1 1;
          margin-left: -140px;
          // padding-left: 60px;
          // justify-content: space-around;

          height: 110px;
          // border-radius: 0 66px 66px 0;
          background: linear-gradient(
            159.78deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(244, 244, 244, 0.9) 100%
          );

          padding-left: 180px;
          padding-right: 50px;
          justify-content: space-between;

          &-initials {
            color: #04071d;
            font-size: 43px;
            line-height: 46px;
          }

          &-score {
            color: #04071d;
            font-size: 43px;
            line-height: 46px;
            text-align: center;
          }
        }

        .itemDefault {
          transform: translateX(0px);
          opacity: 1;
        }

        &-0 {
          transform: translateX(0px);
          opacity: 1;
          --transition-delay: 0.5s;
          &.delay {
            --transition-delay: 1s;
          }
          // top: 335px;
        }

        &-1 {
          transform: translateX(0px);
          opacity: 1;
          --transition-delay: 1s;
          &.delay {
            --transition-delay: 1.5s;
          }
          // top: 605px;
        }

        &-2 {
          transform: translateX(0px);
          opacity: 1;
          --transition-delay: 1.5s;
          &.delay {
            --transition-delay: 2s;
          }
          // top: 875px;
        }

        &-3 {
          transform: translateX(0px);
          opacity: 1;
          --transition-delay: 2s;
          &.delay {
            --transition-delay: 2.5s;
          }
          // top: 1145px;
          // animation-name: fadeInRight;
        }

        &-4 {
          transform: translateX(0px);
          opacity: 1;
          --transition-delay: 2.5s;
          &.delay {
            --transition-delay: 3s;
          }
          // top: 1415px;
        }

        &[hide="true"] {
          position: absolute;
          top: 0;
          transform: translateX(0px);
          z-index: 3;
          opacity: 1;
          transition-delay: 0;
        }

        &.hide {
          transform: translateX(-700px);
          transition-duration: 1s;
          transition-delay: 0;
          opacity: 0;
        }
      }
    }
  }

  &__connection-indicator {
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*=== FADE IN LEFT ===*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
