.Texting {
  .RegistrationPage {
    &__content {
      @extend .full-window,
        .flex,
        .flex--middle,
        .flex--column,
        .bg--blue;

      // justify-content: space-evenly;
      width: 100%;
      flex: 0 1;

      // img {
      //   width: 40vw;
      //   height: auto;
      //   margin-right: -15vw;
      // }
    }

    &__title {
      @extend .font-bell-heavy;

      font-size: 120px;
      line-height: 80px;
      text-align: center;
      color: #fff;
      z-index: 2;

      animation: fadeDown 1.5s ease;
      @keyframes fadeDown {
        from {
          opacity: 0;
          transform: translate3d(0, -100vh, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0vh, 0);
        }
      }
    }

    &__players {
      animation: fadeUp 1.5s ease;
      @keyframes fadeUp {
        from {
          opacity: 0;
          transform: translate3d(0, 100vh, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0vh, 0);
        }
      }
    }
  }
}
