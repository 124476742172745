.Texting {
  &.FinalPage {
    @keyframes fadeWinner {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .PlayerList__item-indicator {
      display: none !important;
    }

    .Winner {
      position: fixed;

      transition: all 1s ease;

      &.animate {
        top: 300px;
        left: 635px;
        opacity: 1;
        animation: fadeWinner 2s forwards;

        .PlayerList-winner {
          transition: all 1s ease;
          flex-direction: row;
          width: 700px;
          height: 314px;

          .PlayerList {
            &__item {
              &-avatar {
                transition: margin-bottom 1s ease;
                margin-bottom: 88px;
              }

              &-indicator {
                @keyframes showCrown {
                  from {
                    transform: translate3d(0, -10vh, 0);
                    opacity: 0;
                  }

                  to {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                  }
                }

                &.winner {
                  opacity: 0;
                  width: 212px;
                  position: relative;
                  margin-bottom: -110px;
                  margin-left: -42px;
                  animation: showCrown 3s forwards 3s;
                }
              }

              &-image {
                @keyframes borderScale {
                  to {
                    width: 314px;
                    height: 314px;
                  }
                }

                border: 10px solid #fff;
                width: 214px;
                height: 214px;
                border-radius: 50%;
                overflow: hidden;
                animation: borderScale 4s forwards;

                img {
                  @keyframes imageScale {
                    to {
                      width: 300px;
                      height: 300px;
                    }
                  }

                  width: 200px;
                  height: 200px;
                  object-fit: cover;
                  margin: 0;
                  animation: imageScale 4s forwards;
                }
              }

              &-initials {
                opacity: 0;
                font-size: 90px;
                margin-left: 51px;
                text-align: left;
                color: #fff;

                animation: fadeinto 4s forwards;

                @keyframes fadeinto {
                  0% {
                    width: 100;
                  }
                  20% {
                    width: 100;
                  }
                  50% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      &-2 {
        &-1 {
          left: 585px;
          top: 743px;
        }

        &-2 {
          left: 1225px;
          top: 743px;
        }
      }

      &-3 {
        &-1 {
          left: 300px;
          top: 743px;
        }

        &-2 {
          left: 855px;
          top: 735px;
        }

        &-3 {
          left: 1410px;
          top: 735px;
        }
      }

      &-4 {
        &-1 {
          left: 189px;
          top: 735px;
        }

        &-2 {
          left: 633px;
          top: 743px;
        }

        &-3 {
          left: 1077px;
          top: 735px;
        }

        &-4 {
          left: 1521px;
          top: 735px;
        }
      }

      &-5 {
        &-1 {
          left: 115px;
          top: 735px;
        }

        &-2 {
          left: 485px;
          top: 743px;
        }

        &-3 {
          left: 855px;
          top: 735px;
        }

        &-4 {
          left: 1225px;
          top: 735px;
        }

        &-5 {
          left: 1595px;
          top: 735px;
        }
      }
    }

    .PlayerList {
      display: flex;
      flex-flow: row;
      flex: 1;
      height: 413px;
      justify-content: center;

      &-placeholder {
        flex-grow: 1;
        max-width: 0px;
        // background-color: #000; // debugging
        height: 300px;

        transition: flex-grow 2s ease-in-out;

        &.hide {
          flex-grow: 0.00001;
        }
      }
    }
  }

  .EndScreenPage {
    &__content {
      @extend .full-window,
        .flex,
        .flex--middle,
        .flex--column,
        .bg--blue;

      justify-content: space-evenly;
      width: 100%;
      flex: 0 1;
    }

    &__players {
      height: 413px;
      padding-top: 35px;
    }

    &__title {
      @extend .font-bell-heavy;

      padding-top: 45px;
      font-size: 95px;
      line-height: 120px;
      text-align: center;
      color: #fff;
    }

    &__canvas {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
