.Texting {
  .SignupPage {
    &__content {
      @extend .flex, .flex--row;

      width: 100%;
      height: 100%;
      flex: 0 1;
    }

    &__column {
      @extend .flex, .flex--center, .flex--middle, .flex--column;

      flex: 0 1 50%;
    }

    &__instruction {
      @extend .flex, .flex--center, .flex--middle, .flex--row;
      @extend .font-bell-heavy;
      width: 582.86px;
      height: 160px;
      margin-top: 20px;
      margin-bottom: -35px;

      &-image {
        width: 90px;
      }

      &-text {
        overflow-wrap: break-word;
        height: 160px;
        font-size: 80px;
        margin-top: -170px;
        margin-left: 55px;
        letter-spacing: 0;
        line-height: 80px;
      }
    }

    &__decoration {
      @extend .Page__bg, .Page__bg--top, .Page__bg--right;
      width: 409px;
      z-index: 1;
    }

    &__logo {
      height: 40vh;
      margin-bottom: 105px;
      margin-left: 95px;
    }

    &__qr {
      @extend .flex, .flex--center, .flex--middle, .flex--column;
      z-index: 2;
      width: 734px;
      height: 734px;
      border: 7px solid #000000;
      border-radius: 37px;
      background-color: #ffffff;
    }

    &__phones {
      width: 911px;
      height: 378px;
      margin-bottom: -18vh;
      margin-left: 95px;
    }

    &__animation {
      animation: diagonalFade 1s ease;
      @keyframes diagonalFade {
        from {
          opacity: 0;
          transform: translate3d(-100vw, 100vh, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0vw, 0vh, 0);
        }
      }
    }
  }
}
