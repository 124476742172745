@import "./home.scss";
@import "./signup.scss";
@import "./registration.scss";
@import "./endscreen.scss";
@import "./question.scss";

.Quiz {
  @extend .font-bell-heavy;

  .PlayerList {
    @extend .flex, .flex--center;

    flex: 0.00001 384px;
    min-height: 413px;
    justify-content: center;

    &__item {
      @extend .flex, .flex--column, .flex--middle, .flex--center;
      height: 314px;
      // width: 480px;
      flex: 0.00001 0 384px;
      left: var(--el-left);
      top: var(--el-top);

      transition: all 1s linear;

      @keyframes flexGrow {
        from {
          opacity: 0;
          transform: translate3d(10vw, 0, 0);
          flex: 0.00001 0 0.0001px;
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          flex: 1 0 384px;
        }
      }

      &.registered {
        width: 0px;
        max-width: 384px;
        animation: flexGrow 1s linear;
      }

      &.finalWinner {
        opacity: 0;
        flex-direction: column;
      }

      @keyframes resetFilter {
        to {
          filter: grayscale(0%);
        }
      }

      @keyframes greyScalFilter {
        to {
          filter: grayscale(100%);
        }
      }

      &-image {
        border: 10px solid #fff;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        animation: resetFilter 1s forwards;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 180px;
          height: 180px;
          object-fit: cover;
          margin: 0;
        }

        &.loser {
          animation: greyScalFilter 1s forwards;
        }
      }

      &-avatar {
        position: relative;
        // height: 314px;
      }
      &-avatarheader {
        width: 200px;
        height: 100px;
        position: relative;
      }

      &-indicator {
        position: absolute;
        width: 160px;
        margin: 0 20px;
        z-index: 4;
        top:0;

        @keyframes indicatorUpQuiz {
          from {
            opacity: 0;
            top: 65px;
          }

          to {
            opacity: 1;
            top: 0px;
          }
        }

        &.winner {
          opacity: 1;

          animation: indicatorUpQuiz 1s forwards;
          &--notShow {
            opacity: 0;
          }
        }

        &.loser {
          opacity: 1;

          animation: indicatorUpQuiz 1s forwards;
          &--notShow {
            opacity: 0;
          }
        }

        &.finalWinner {
          opacity: 1;
          width: 156px;
          position: relative;
          margin-bottom: 70px;
          margin-left: 20px;
        }
      }

      &-initials {
        font-size: 37px;
        text-align: center;
        color: #fff;
        // z-index: 1;

        &.firstAnnouncement {
          opacity: 0;
          animation: showScore 1s forwards 2s;

          @keyframes showScore {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__connection {
    &-indicator {
      display: none;
    }
  }
}
