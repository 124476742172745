.Texting {
  &.GamePage {
    .Page__content {
      @extend .bg--blue;
    }
  }

  .GamePage {
    &__container {
      color: #fff;
      @extend .flex, .flex--column;

      height: 100%;
      overflow: hidden; // height: var(--height);
    }

    &__header {
      @extend .flex, .flex--center, .flex--middle;
      padding-bottom: 50px;
      padding-top: 35px;

      &-logo {
        padding: 10px;
      }

      &-text {
        font-size: 50px;
        padding: 10px;
      }

      .Timer {
        height: 150px;
        width: 150px;
        margin: 0;

        &__svg {
          height: 150px;
          width: 150px;
        }

        &__label {
          height: 150px;
          width: 150px;
          line-height: 150px;
          font-size: 65px;
        }
      }
    }

    &__players {
      @extend .flex, .flex--column, .flex--middle;
      justify-content: space-around;
      flex: 0 1;
      margin: 0 20px 0 80px;
    }

    &-Player {
      @extend .flex, .font-bell-heavy;
      position: relative;
      margin-bottom: 70px;

      &::after {
        content: " ";
        background-image: url("../../assets/1-player.png");
        background-size: cover;
        width: 1934px;
        height: 181px;
        position: absolute;
        top: 50px;
        left: -80px;
        right: 0;
        opacity: 0.6;
      }

      &:nth-child(even) {
        &::after {
          background-image: url("../../assets/2-player.png");
          background-size: cover;
        }

        .GamePage-Player__progress {
          &::before {
            background-image: url("../../assets/2-player-path.png");
            top: 35px;
            left: 35px;
          }

          &-indicator {
            offset-path: path(
              "M3 30.482214h16.638406c7.920743 0 63.363266 5.73125 82.898515 5.73125 78.595892 2.296875 241.086701-37.78125 357.151843-32.78125C612.734375 6.197839 745.67188 45.638791 819.66016 46.330652c73.98828.69186 275.60547-37.835938 425.21093-37.835938 34.80079-.816406 59.32813 0 71.12891 0"
            );
          }
        }
      }
      &__avatar {
        @extend .flex, .flex--column, .flex--middle;
        z-index: 1;
        width: 100px;
        align-content: center;
        align-items: center;
      }

      &__profile {
        @extend .PlayerList__item-image;

        max-width: 100px;
        max-height: 100px;
        z-index: 9;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &__name {
        font-size: 25px;
        line-height: 25px;
        padding-top: 140px;
        text-align: center;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
      }

      &__progress {
        flex: 1 0;
        position: relative;
        max-width: 1530px;

        &::before {
          content: " ";
          background-image: url("../../assets/1-player-path.png");
          background-size: cover;
          width: calc(var(--progress) * 1%);
          transition: width 1400ms;
          height: 58px;
          position: absolute;
          top: 30px;
          left: 26px;
          right: 0;
          opacity: 1;
          z-index: 1;
        }

        &-indicator {
          position: absolute;
          top: 24px;
          z-index: 10;
          margin-left: 190px;

          offset-path: path(
            "M3 7.626953h72.97957C179.017851 4.880859 397.448803 50.42954 470.760038 48.412109 544.071274 46.394679 726.38309 3 817.59012 3s331.60742 30.755859 397.55664 30.755859c43.96614 0 77.51041-1.604817 100.63281-4.814453"
          );

          transition: offset-distance 1000ms ease-in-out;

          offset-distance: 0%;
          offset-distance: calc(var(--progress) * 1%);

          &-artifact {
            position: absolute;
            transition: all ease 0.5s;
            left: 30%;
            z-index: -1;
            opacity: 0;
            top: 0;
            text-align: center;

            img {
              height: 100px;
              opacity: 80%;
            }

            & > div {
              position: absolute;
              font-size: 40px;
              top: 0px;
              line-height: 80px;
            }

            &.animate {
              top: -80px;
              opacity: 1;
            }
          }
        }
      }

      &__score {
        position: absolute;
        padding: 20px;
        right: -120px;
        bottom: 0px;
        z-index: 9;

        &-value {
          position: absolute;
          top: 37px;
          color: #fff;
          font-size: 35px;
          width: 158px;
          text-align: center;
        }

        &-image {
          // width: 150px;
        }
      }
    }
  }
}
